import React, { useEffect } from "react";
import config from "../../../../config";
import FilesDisplayList from "../file-ies-ldt/files-display-list";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import RoadProfile from "../definition/road-profile";

import DeleteModal from "../components/delete-modal";

export default function FileRoadDefinition(){
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const dispatch = useDispatch();
  const { launch_request } = useAzureFuncApp();
  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);
  const navigate = useNavigate();

  const [fileList, setFileList] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(undefined);
  const [wantDeleteFile, setWantDeleteFile] = React.useState(undefined);
  const [showDeleteModal,setShowDeleteModal] = React.useState(false);

  const [roadData,setRoadData] = React.useState()
  const [roadGeomData,setRoadGeomData] = React.useState()

  useEffect(() => {
    getRoadsProfile().then((result) => {
      if (result !== undefined) {
        setFileList(result)
      }
    } 
  );
  }, [])

  async function handleFileSelected (event){

    let selectedId = event.target.dataset.key;
    let selectedFileById = fileList.find(x => x.id.toString() === selectedId);
    setSelectedFile(selectedFileById);
    let roadDataFile = await fetch(selectedFileById["file_path"]).then((response) =>
    response.json());
    setRoadGeomData(roadDataFile.road_profile_data)
    setRoadData({
      'name':selectedFileById.name,
      'lightsHeight':parseFloat(roadDataFile.lightsHeight) || 0,
      'lightingClass':roadDataFile.lightingClass,
      'category':roadDataFile.category,
      'lights_dist':parseFloat(roadDataFile.lights_dist) || 0,
      'lights_dist_road':parseFloat(roadDataFile.lights_dist_road) || 0,  
      'light_dispo':roadDataFile.light_dispo || 'up',
    });
  }
  const handleDeleteFile = (item) => {
    setWantDeleteFile(item);
    setShowDeleteModal(true);
  }
  const handleNotDeleteFile = (event) => {
    setShowDeleteModal(false);
  }

  async function getRoadsProfile() {
    const payload = {
      functionName: "getRoadsProfiles",
      projectPath: projectInfos.project_path || "",
    };
    setLoadingMessage("Récupération des profils de route...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
      if (status_code === 200) {
      setIsLoading(false);
      return result.roadsList
    }
  }
  async function deleteFile(item) {
    let blobPath = item.file_path
    const payload = {
      functionName: "deleteRoadFile",
      roadFilePath:`${projectInfos.project_path}/inputs/roads_profile/${item.name}.json`,
    };
    setLoadingMessage("Suppression du fichier...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    setIsLoading(false);
    if (status_code === 200) {
      if (selectedFile !== undefined && selectedFile.file_path === blobPath) {
        setSelectedFile(undefined);
      }

      setFileList(fl => [...fl].filter(x => x.file_path !== blobPath));
    }

    setShowDeleteModal(false);
  }

    return <>
        <div className="lg:col-span-6 xl:col-span-4 flex flex-col py-4 max-w-[20%]">
            <FilesDisplayList
                fileList={fileList}
                buttonAddFileText="Ajouter un tronçon"
                onAddFile={() =>{navigate("/solux-map/definition")}}
                onDeleteFile={handleDeleteFile}
                onFileSelected={handleFileSelected} 
            />

            <DeleteModal isOpen = {showDeleteModal}  onConfirm = {deleteFile}  onDecline={handleNotDeleteFile} itemToDelete={wantDeleteFile} />
        </div>
            {roadData && <RoadProfile  _roadData ={roadData} _setRoadData={setRoadData} _roadGeomData={roadGeomData} _setRoadGeomData={setRoadGeomData}/>}
    </>
}