import { Canvas, useThree } from '@react-three/fiber';
import * as THREE from 'three';

import { Icon } from "@iconify/react";
import { Button, Input, Select, SelectItem, Tooltip } from "@nextui-org/react";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import config from "../../../../config";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";

import { useOutletContext } from 'react-router-dom';
import WarningModal from '../components/warning-modal';
import { lightsCat, roadCat } from '../utils/constants';
import ConfirmationSavedModal from './confirmation-saved-modal';
import RoadTypeCard from "./road-type-card";

export default function RoadProfile({_roadData,_setRoadData,_roadGeomData,_setRoadGeomData}){
  const { launch_request } = useAzureFuncApp();
  const project_infos = useSelector((state) => state.PYM.ProjectInfos);
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  
    // const [selectedRoadType, setSelectedRoadType] = React.useState(_roadProfileData[0]);


    const [showModal,setShowModal] = useState(false);
    const [showModalWarning,setShowModalWarning] = useState(false);

    const [messagesWarnings,setMessagesWarnings] = React.useState("");


  // Gestion des comportements 
  function FitView() {
    const { camera, scene } = useThree();

    useEffect(() => {
      const box = new THREE.Box3().setFromObject(scene);
      const size = box.getSize(new THREE.Vector3()).length();
      const center = box.getCenter(new THREE.Vector3());

      camera.position.set(center.x, center.y, size * 1.5); // Position au-dessus de la scène
      camera.zoom = 50; // Zoom initial
      camera.updateProjectionMatrix();

      camera.lookAt(center.x, center.y, 0); // Regarder vers le bas
    }, [scene, camera]);
    return null;
  }
  const Rectangle = ({ width, height, color, position }) => {
    return (<>
      <mesh position={position}>
        <planeGeometry args={[width, height]} />
        <meshStandardMaterial color={color} />
      </mesh>
    </>
    );
  };

  function readyToGoNext() {
    let messages = [];
    let isValid = true;
    if (_roadData.name === undefined || _roadData.name.trim() === "") {
        messages.push("- Merci de renseigner un nom pour le tronçon type.");
        isValid = false;
    }
    if (_roadData.category === undefined || _roadData.category.trim() === "") {
        messages.push("- Merci de renseigner une catégorie pour la voirie.");
        isValid = false;
    }
    if (_roadData.lightingClass === undefined || _roadData.lightingClass.trim() === "") {
        messages.push("- Merci de renseigner une classe d'éclairage.");
        isValid = false;
    }

    if (_roadData.lights_dist === undefined || _roadData.lights_dist < 0) {
      messages.push("- Merci de renseigner une interdistance entre luminaires.");
      isValid = false;
  }

    if (_roadData.lightsHeight === undefined || _roadData.lightsHeight < 0) {
        messages.push("- Merci de renseigner une hauteur de luminaire.");
        isValid = false;
    }
    if (_roadData.lights_dist_road === undefined|| _roadData.lights_dist_road < 0) {
      messages.push("- Merci de renseigner une distance luminaire - voirie.");
      isValid = false;
  }
  if (_roadData.light_dispo === undefined|| _roadData.light_dispo.trim() === "") {
    messages.push("- Merci de renseigner une disposition pour les luminaires.");
    isValid = false;
}

    if (!isValid) {
       setMessagesWarnings(messages.join("\n\n"));
       setShowModalWarning(true);
    }

    return isValid;
  }

  const handleSaveRoadType = async (item) => {
    if(readyToGoNext()){
      let payload = {
        'functionName': 'saveRoadType',
        'pathFiles': project_infos.project_path,
        'name': _roadData.name,
        'category':_roadData.category,
        'lightsHeight':_roadData.lightsHeight,
        'lightsDist':_roadData.lights_dist,
        'lightsDistRoad':_roadData.lights_dist_road,
        'lightingClass':_roadData.lightingClass,
        'light_dispo':_roadData.light_dispo,
        'data': _roadGeomData
      }
      setLoadingMessage("Sauvegarde des tronçons types...");
      setIsLoading(true);
      let [status_code, result] = await launch_request(
        config.pymParseFile,
        payload
      );
      if (status_code === 200) {
        setShowModal(true);
      }
      setIsLoading(false);
    }
    else{

    }
  }
  const handleRowSelected = (item) => {
    // setSelectedRoadType(item);
  }
    const handleChange = (value,key) =>{
      _setRoadData((items) => {
        let prevItems = {
          ...items,
          [key]: value
      };
      return prevItems
    });
     
  }
  const isInvalidDist = React.useMemo(() => {
    if (_roadData.lights_dist === "") return false;
    return  _roadData.lights_dist > 0 ? false : true;
  }, [_roadData.lights_dist]);
  
  const isInvalidDistRoad = React.useMemo(() => {
    if (_roadData.lights_dist_road === "") return false;
    return _roadData.lights_dist_road >= 0 ? false : true;
  }, [_roadData.lights_dist_road]);

  const isInvalidH = React.useMemo(() => {
    if (_roadData.lightsHeight === "") return false;
    return _roadData.lightsHeight > 0 ? false : true;
  }, [_roadData.lightsHeight]);
    return (
        <>
            <div className='w-full flex flex-col mt-2 ml-2 mr-2 mb-2'>
            <div className='flex flex-row gap-2 items-center my-4'>
                <Input
                label="Nom de la catégorie de voirie"
                value={_roadData.name}
                onValueChange={(value) => {
                    handleChange (value,"name")
                }
                }
                />
                <Select
                label="Catégorie"
                className="max-w-xs my-4"
                defaultSelectedKeys={[_roadData.category]}
                onSelectionChange={(event)=>{
                    handleChange(event.currentKey,"category")
                }}
                >
                {roadCat.map((cat) => (
                    <SelectItem  key={cat}>
                    {cat}
                    </SelectItem>
                ))}
            </Select>

            <Select
                label="Classe d'éclairage"
                className="max-w-xs my-4"
                defaultSelectedKeys={[_roadData.lightingClass]}
                onSelectionChange={(event)=>{
                    handleChange(event.currentKey,'lightingClass')
                    }}
                >
                {Object.keys(lightsCat).map((cat) => (
                <SelectItem  key={cat} textValue={cat}>
                    <Tooltip 
                    key={cat} 
                    content={
                      cat.includes('M') ? <p>Éclairement moyen : {lightsCat[cat]['cd_m2']} cd/m²  <br/>Uniformité: {lightsCat[cat]['uniformity']}</p> :
                          <p>Éclairement moyen : {lightsCat[cat]['e_moy']} lux  <br/>Uniformité: {lightsCat[cat]['uniformity']}</p>                            
                  }
                    >
                    {cat}
                    </Tooltip>
                </SelectItem>
                ))}

            </Select>
            <Input
                label="Interdistance entre mâts (m)"
                value={_roadData.lights_dist}
                isInvalid={isInvalidDist}
                color={isInvalidDist && "danger" }
                onValueChange={(value)=>{
                    handleChange(parseFloat(value)||0,"lights_dist");
                }}
                />
            <Input
                label="Hauteur des mâts (m)"
                value={_roadData.lightsHeight}
                isInvalid={isInvalidH}
                color={isInvalidH && "danger" }
                onValueChange={(value)=>{
                    handleChange(parseFloat(value)||0,"lightsHeight");
                }}
                />
                <Input
                label="Distance luminaire - voirie (m)"
                value={_roadData.lights_dist_road}
                isInvalid={isInvalidDistRoad}
                color={isInvalidDistRoad && "danger" }
                onValueChange={(value)=>{
                    handleChange(parseFloat(value)||0,"lights_dist_road");
                }}
                />

                <Button isIconOnly onClick={() => { handleSaveRoadType() }} className='h-[63%] bg-[#80ed99] ' ><Icon icon="fluent:save-24-regular" width={55} height={55} style={{ 'color': 'white' }} /></Button>
            </div>
            <div className='flex flex-row align-items h-full overflow-y-hidden'>
            <RoadTypeCard
                roadProfileData={_roadGeomData}
                roadData={_roadData}
                setRoadProfileData={_setRoadGeomData}
                setRoadData = {_setRoadData}
                onRowSelected={handleRowSelected}
            />
            <div className='w-[50%]'>
                <Canvas orthographic camera={{ position: [0, 0, 100], zoom: 50 }}>
                {_roadGeomData.map((road, index) => (
                    <Rectangle
                    key={index}
                    width={road.rectangle.width}
                    height={road.rectangle.height}
                    color={road.rectangle.color}
                    position={road.rectangle.position}
                    />
                ))}
                <ambientLight intensity={0.1} />
                <directionalLight position={[0, 0, 5]} />
                <FitView />
                </Canvas>
            </div>
            </div>
            <ConfirmationSavedModal isOpen={showModal} onConfirm={()=>{setShowModal(false)}} onDecline={()=>{setShowModal(false)}}/>
            <WarningModal isOpen={showModalWarning} onConfirm={()=>{setShowModalWarning(false)}} message={messagesWarnings} buttonOkText ={"Renseigner les valeurs"}/>
            </div>

        </>
    )
}