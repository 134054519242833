import { Button,  Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,Image} from "@nextui-org/react";
import { Icon } from "@iconify/react";
import FileLoader from "../../../../components/file/FileLoader";
import { useSelector } from "react-redux";

const containerName = "pym";
const service = "Diane";
export default function DeleteModal({ isOpen,onConfirm,onDecline, itemToDelete,buttonText = "Êtes-vous sûr de vouloir supprimer ce fichier ?" }) {
    return (
        <Modal 
        isOpen = {isOpen}
        onClose={onDecline}
        backdrop='blur'
        size="s"
        placement = "center"> 
        <ModalContent>
            <ModalHeader className="flex items-center gap-1">
                <Icon icon="solar:shield-warning-broken"  style={{ color: '#f31260' }} />
                Attention
            </ModalHeader>
            <ModalBody>
                <p>{buttonText}</p>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" variant="flat" onClick={() => {
                    onConfirm(itemToDelete);
                }}>
                    Supprimer
                </Button>
                <Button color="primary" variant="flat" onPress={() => {
                    onDecline();
                }}>
                    Annuler
                </Button>
            </ModalFooter>
    </ModalContent>
        </Modal>
    )
}