import React, { useEffect, useMemo, useState } from "react";
import FileLoader from "../../../../components/file/FileLoader";
import EditableTable from "../../../../components/table/editable-table";
import { useDispatch, useSelector } from "react-redux";

import {Icon} from "@iconify/react";
import { Button, Chip, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tooltip, useDisclosure } from "@nextui-org/react";
import { ActualizeIcon, DeleteIcon } from "../../../../components/Icons";
import { changeStatus, setSasPdf, deleteFile, setCurrentFile, addProjectFile } from "../../../../store/features/Comptage/project-info-slice";
import config from "../../../../config";
import { setFileConfiguration, setSegmentationReady } from "../../../../store/features/Comptage/configuration";
import { clearFiles, setFiles } from "../../../../store/features/PDF/FileSlice";
import { setHits } from "../../../../store/features/Comptage/decompte";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import { setCalibration } from "../../../../store/components/PolygonEditor/ImageSlice";
import { useOutletContext } from "react-router-dom";
import YesNoAlert from "../../../../components/modals/AlertYesNo";
import Alert from "../../../../components/modals/Alert";
import { useEntraUser } from "../../../../EntraUserProvider";

const statusColorMap = {
    ready: {
        name: "Prêt",
        color:"success"
    },
    inprogress: {
        name: "En cours de préparation",
        color:"warning"
    },
    unready: {
        name: "Non préparé",
        color:"danger"
    }
  };


const containerName = "comptagev2";
let changingCurrentFile=false;

export default function Files () {
    const {isLoading, setIsLoading, loadingMessage, setLoadingMessage} = useOutletContext();
    const {isOpen, onOpen, onOpenChange: defaultOnOpenChange, onClose} = useDisclosure();
    const { launch_request } = useAzureFuncApp();
    const { userData } = useEntraUser();
    const isAdmin = useMemo(() => {
        return userData.companyName === "Diane - 10426" || userData.companyName === "Diane";
      }, [userData]);

    const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
    const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
    const projectName = useSelector((state) => state.Comptage.ProjectInfos.project_name);
    const projectPages = useSelector((state) => state.Comptage.Configuration.pages);
    const user = useSelector((state) => state.Comptage.ProjectInfos.creator_name);
    const dispatch = useDispatch();
    
    const data = useMemo(() => {
        return projectFiles.map((x, i) => {
            return {
                id: i,
                photo: undefined,
                name: x.name,
                pages: x.pages === undefined ? 0 : x.pages.length,
                status: x.status,
                is_current: x.is_current,
                action: undefined
            };
        });
    }, [projectFiles]);

    const [filters, setFilters] = useState([]);

    useEffect(() => {
        if (!changingCurrentFile) {
            prepareFirstFile();
        }

        async function prepareFirstFile() {
            if (data.every(d => d.status === "unready") && data.length > 0) {
                changingCurrentFile = true;
                await actualizeFile(data[0].name);
                changingCurrentFile = false;
            }
        }
    }, [data.length]);

    const onChangeCurrentFile = async (filename) => {
        setLoadingMessage("Changement du fichier courant...");
        setIsLoading(true);

        // Set current file
        let payload = {
            'function': 'set_current',
            'username': user,
            'project_name': projectName,
            'pdf_name': filename,
            'container_name': containerName
        };
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            dispatch(setCurrentFile(filename));

            dispatch(setFileConfiguration({
                current_file: result.filename,
                pages: result.pages,
                zones: result.zones,
                symbols: result.symbols,
                linears: result.linears,
                legend: result.legend
            }));

            dispatch(setCalibration(result.calibration));

            dispatch(setHits({
                hits: result.hits,
                metres: result.metres
            }));
        }

        setIsLoading(false);
    };

    const prepareSegmentation = async (page, filename) => {
        let segmentationReady = projectPages.find(x => x.page === page-1)?.segmentation_ready;
        if (!segmentationReady || segmentationReady === "not_started") {
            dispatch(setSegmentationReady({ page: page-1, segmentation: "ongoing"}))

            let payload = {
                "function": "segment_items",
                "username": user,
                "project_name": projectName,
                "container_name": containerName,
                "pdf_name": filename,
                "page_number": page
            };

            let [status_code, result] = await launch_request(config.apiSegmentationUri, payload, {alertError : false});
            
            if (status_code === 200) {
                segmentationReady = result.segmentation;
            } else if (status_code === 504) {
                segmentationReady = "ongoing";
            } else {
                segmentationReady = "failed";
            }

            if (segmentationReady !== "ongoing") {
                dispatch(setSegmentationReady({ page: page-1, segmentation: segmentationReady}));
            }
        };

        if (segmentationReady === "ready") {
            Alert("Fichier prêt",
                `Les fonctions de légende et comptage automatique sont à présent disponibles pour la page ${page} du fichier ${filename} (projet ${projectName}).`
                );
        } else if (segmentationReady === "failed") {
            Alert("Fichier prêt",
                `Les fonctions de légende automatique et comptage sur l'ensemble des symboles ne sont pas disponibles pour la page ${page} du fichier ${filename} (projet ${projectName}). Vous pouvez cependant utiliser les autres fonctions disponibles et compter les symboles individuellement!`
                );
        };
    };


    const actualizeFile = async (filename) => {
        dispatch(changeStatus({
            filename: filename,
            status: "inprogress"
        }));

        setLoadingMessage("Préparation du fichier...");
        setIsLoading(true);

        prepareSegmentation(1, filename);

        let payload = {
            "function": "pdf2img",
            "container_name": containerName,
            "project_name": projectName,
            "username": user,
            "file": filename,
            "all_pages": false,
            "selected_pages_numbers": []
        };
    
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            let url_pdf = result.url_pdf;
            let previews = result.previews;
            let pages = result.pages;
            dispatch(setSasPdf({
                filename: filename,
                url: url_pdf,
                previews: previews
            }));
            dispatch(changeStatus({
                filename: filename,
                status: "ready",
                pages: pages
            }));

            if (result.set_current) {
                await onChangeCurrentFile(filename);
            }
        } else {
            
            dispatch(changeStatus({
                filename: filename,
                status: "unready"
            }));
        }

        setIsLoading(false);

        if (!["ready", "failed"].includes(projectPages[0]?.segmentation_ready)) {
            Alert("Fichier en cours de préparation",
                `Les fonctions de légende automatique et comptage sur l'ensemble des symboles sont en cours de préparation pour la page 1. Cela peut prendre quelques minutes. Nous vous préviendrons lorsque le fichier sera prêt.`
            );
        }
    };

    const onDeleteFile = async (filename) => {
        setLoadingMessage("Suppression du fichier...");
        setIsLoading(true);

        // Delete file
        let payload = {
            'function': 'delete_file',
            'username': user,
            'project_name': projectName,
            'pdf_name': filename,
            'container_name': containerName
        };
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            dispatch(deleteFile(filename));

            if (filename === currentFile) {
                dispatch(setCurrentFile(undefined));
            }
        }

        setIsLoading(false);
    };
    
    const columns = [
        {
            uid: 'photo',
            name: '',
            isEditable: false,
            type: 'image',
            height: 50,
            sortable: false
        },
        {
            uid: 'name',
            name: 'Nom du fichier',
            isEditable: false,
            type: 'text',
        },
        {
            uid: 'pages',
            name: 'Nombre de pages',
            isEditable: false,
            type: 'number',
            sortable: false
        },
        {
            uid: 'is_current',
            name: '',
            isEditable: false,
            type: 'bool',
            renderCell: (item) => {
                if (item.is_current) {
                    return (<><span className="bg-green-600 rounded-[50%] inline-block w-4 h-4 text-center align-middle leading-4">&#x2713;</span> Sélectionné</>);
                } else {
                    return (<Button isDisabled={item.status !== "ready"} onClick={() => { onChangeCurrentFile(item.name); }}>Sélectionner</Button>);
                }
            }
        },
        {
            uid: 'status',
            name: 'Etat',
            isEditable: false,
            type: 'text',
            renderCell: (item) => {
                return ( <Chip color={statusColorMap[item.status].color}>{statusColorMap[item.status].name}</Chip>);
            }
        },
        {
            uid: 'action',
            name: '',
            isEditable: false,
            type: 'text',
            sortable: false,
            renderCell: (item) => {
                return (<div className="flex flex-row justify-center gap-3">
                    {(item.status === "unready") && <Tooltip color="danger" content="Actualiser le fichier">
                        <span
                        className="text-lg text-danger cursor-pointer active:opacity-50"
                        onClick={() => { actualizeFile(item.name); }}
                        >
                        <ActualizeIcon style={{ color: 'black' }}/>
                        </span>
                    </Tooltip>}
                    <Tooltip color="danger" content="Supprimer le fichier">
                        <span
                        className="text-lg text-danger cursor-pointer active:opacity-50"
                        onClick={() => {
                            YesNoAlert({
                                title: "Supprimer",
                                body: "Voulez-vous vrament supprimer le fichier ?",
                                onYes: () => {
                                    onDeleteFile(item.name);
                                }
                            })
                        }}
                        >
                            <DeleteIcon />
                        </span>
                    </Tooltip>
                </div>)
            }
        }
    ];

    const addFile = async (filename, arrayBuffer, totalPages, index) => {
        dispatch(addProjectFile({
            name: filename,
            pages: [],
            is_current: false,
            status: "inprogress"
        }));

        setLoadingMessage("Préparation du fichier...");
        setIsLoading(true);
        onClose();

        prepareSegmentation(1, filename);

        dispatch(setFiles([]));

        let payload = {
            "function": "pdf2img",
            "container_name": containerName,
            "project_name": projectName,
            "username": user,
            "file": filename,
            "all_pages": false,
            "selected_pages_numbers": []
        };
    
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            let url_pdf = result.url_pdf;
            let previews = result.previews;
            let pages = result.pages;
            dispatch(setSasPdf({
                filename: filename,
                url: url_pdf,
                previews: previews
            }));
            dispatch(changeStatus({
                filename: filename,
                status: "ready",
                pages: pages
            }));

            if (result.set_current) {
                await onChangeCurrentFile(filename);
            }
        }

        setIsLoading(false);

        if (!["ready", "failed"].includes(projectPages[0]?.segmentation_ready)) {
            Alert("Fichier en cours de préparation",
                `Les fonctions de légende automatique et comptage sur l'ensemble des symboles sont en cours de préparation pour la page 1. Cela peut prendre quelques minutes. Nous vous préviendrons lorsque le fichier sera prêt.`
            );
        }
    };

    const onOpenChange = (isOpen) => {
        dispatch(clearFiles());
        defaultOnOpenChange(isOpen);
    };

    return (
        <div className="flex flex-col justify-start items-end w-full gap-5 px-5 py-5">
            <Button className="min-w-[10%] flex flex-row justify-between" onPress={isAdmin ? () => { YesNoAlert({
                title: "Mode administrateur",
                body: `Vous êtes en mode administrateur. Êtes-vous sûr de continuer ?`,
                onYes: onOpen
            })} : onOpen}>
                <Icon icon="ic:baseline-plus" />
                <span>Ajouter</span>
                <span></span>
            </Button>
            <EditableTable
                columns={columns}
                data={data}
                filters={filters}
                showTopRibbon={false}
                // removeWrapper
            />
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} className="max-w-fit">
                <ModalContent>
                {(o) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1"></ModalHeader>
                    <ModalBody>
                        <FileLoader
                            title="Ajoutez un fichier"
                            multiple={false}
                            containerName={containerName}
                            fileType="pdf"
                            className="min-w-[450px]"
                            blobNamePrefix={`${user}/${projectName}/`}
                            onFileLoaded={addFile}
                            backgroundColor="#EFEFEF"
                        />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    );
};
