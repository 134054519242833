import { createSlice } from "@reduxjs/toolkit";

const GeoSlice = createSlice({
  name: "geo_slice",
  initialState: {
    zoom_point: undefined,
    city_lights_data: [],
    city_lights_files: [],
    city_lights_positions: [],
    roads_data: [],
    selected_roads:[],
  },
  reducers: {
    setGeoData: (state, action) => {
      const { zoom_point, city_lights_positions, city_lights_data, city_lights_files, roads_data,selected_roads} = action.payload;
      state.zoom_point = zoom_point;
      state.city_lights_positions = city_lights_positions;
      state.city_lights_data = city_lights_data;
      state.city_lights_files = city_lights_files;
      state.roads_data = roads_data;
      state.selected_roads = selected_roads;
    },
    updateRoadsProperties: (state, action) => {
      state.roads_data.features.forEach(feature => {
        const update = action.payload.find(u => u.id === feature.properties.id);
        if (update) {
            feature.properties = { ...feature.properties, ...update };
        }
    });
    },
    DeleteRoadsOnProperties: (state, action) => {
      console.log(state.roads_data);
      state.roads_data = state.roads_data.filter((road) => road.properties.id !== action.payload.id);
    },

    updateLightsData: (state, action) => {
      state.city_lights_data = action.payload;
    },
    updateLightsFiles: (state, action) => {
      state.city_lights_files = action.payload;
    },

    updateSelectedRoads: (state, action) => {
      state.selected_roads = action.payload;
    },

  },
});

export const { setGeoData, updateLightsFiles, updateSelectedRoads, updateRoadsProperties,DeleteRoadsOnProperties } = GeoSlice.actions;
export default GeoSlice.reducer;
