import { Chip } from "@nextui-org/react";
import { domAnimation, LazyMotion, m } from "framer-motion";
import React, { useCallback, useEffect, useMemo,useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import RedirectionModal from "../components/redirection-modal";
import EditableTable from "../../../../components/table/editable-table";
import config from "../../../../config";
import LeafletMap from "../map";
import MultistepSidebar from "./multistep-sidebar";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import { useSoluxMap } from "../side-bar/provider";

const variants = {
  enter: (direction) => ({
    y: direction > 0 ? 30 : -30,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    zIndex: 0,
    y: direction < 0 ? 30 : -30,
    opacity: 0,
  }),
};

const statusColorMap = {
  good: "success",
  bad: "danger",
};

export default function Calculs() {
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  // Declaration des states
  const { launch_request } = useAzureFuncApp();
  const { cityLightsData, setCityLightsData,roadsData } = useSoluxMap();

  const [[page, direction], setPage] = React.useState([0, 0]);
  const [plotPath, setPlotPath] = React.useState("");
  const navigate = useNavigate();

  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);
  const geoDataInfos = useSelector((state) => state.PYM.GeoData);

  const lightsFiles = useSelector((state) => state.PYM.GeoData.city_lights_files);

  const [filters, setFilters] = React.useState([]);

  const [showModal,setShowModal] = useState(false);
  const [calculationZone,setCalculationZone] = useState([])



  // Gestion des comportements
  useEffect(() => {
    if (page === 2) {
      launchSimulation();
    }
  }, [page])

  useEffect(()=>{
    if(!lightsFiles || lightsFiles.length ===0){
      setShowModal(true);
    }
  },[lightsFiles]);

  const handleOnValueChange = useCallback((item) => {
    const fichier = item.properties.fichier;
    let newItem = { ...item };
  
    let value = typeof fichier === "string" ? fichier : fichier.length > 0 ? fichier[0] : "";
    let newStatus = (value === "" || value === "inconnu") ? "bad" : "good";
    newItem.properties = { ...newItem.properties, status: newStatus };

    return newItem;
  }, []);

  var initial_visible_columns = useMemo(() => { 
    return [
      {
        name: "Code",
        uid: "code",
        valueAccessor: (item) => item.properties.code,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, code: value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
      },
      {
        name: "Marque",
        uid: "marque",
        valueAccessor: (item) => item.properties.marque,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, marque: value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
      },{
        name: "Modèle",
        uid: "modele",
        valueAccessor: (item) => item.properties.modele,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, modele: value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
      },
      {
        name: "Source",
        uid: "source",
        valueAccessor: (item) => item.properties.source,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, source: value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
      },
      {
        name: "Fichier",
        uid: "fichier",
        valueAccessor: (item) => item.properties.fichier,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, fichier: value };
          return newItem;
        },
        isEditable: true,
        type: 'list',
        listItems: lightsFiles,
        width: "500px",
        onValueChange: (item) => handleOnValueChange(item)
      },
      {
        name: "Hauteur",
        uid: "hauteur",
        valueAccessor: (item) => item.properties.hauteur,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, hauteur: value };
          return newItem;
        },
        isEditable: true,
        type: 'number',
        width: "500px",
      },

      {
        name: "Status",
        uid: "status",
        valueAccessor: (item) => item.properties.status,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem.properties = { ...newItem.properties, status: value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
        renderCell: (item) => {
          return (<Chip
            className="capitalize border-none gap-1 text-default-600 flex flex-row"
            color={statusColorMap[item.properties.status]}
            size="sm"
            variant="dot"
          ></Chip>)
        }
      },
    ];
  }, [handleOnValueChange, lightsFiles]);


  const selected_lights = useMemo(() => {
    if (calculationZone.length === 0) return cityLightsData;
    let [latMin, lonMin] = [Math.min(calculationZone[0][0], calculationZone[1][0]), Math.min(calculationZone[0][1], calculationZone[1][1])];
    let [latMax, lonMax] = [Math.max(calculationZone[0][0], calculationZone[1][0]), Math.max(calculationZone[0][1], calculationZone[1][1])];
    let filteredPoints = cityLightsData.filter(({ geometry: { coordinates: [lon, lat] } }) => 
        latMin <= lat && lat <= latMax && lonMin <= lon && lon <= lonMax
    );
    return filteredPoints;
  }, [calculationZone, cityLightsData]);

  const allStatusGood = useMemo(() => {
    console.log("selected_lights",selected_lights)
    return selected_lights.every(x => x.properties.status === "good");
  }, [,selected_lights,cityLightsData]);

  async function launchSimulation() {

    const payload = {
      lightsData: cityLightsData,
      roadsData: geoDataInfos.roads_data,
      zoneCalcul : calculationZone,
      pathFile: projectInfos.project_path,
    };

    setLoadingMessage("Simulation des éclairages...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymSimulation,
      payload
    );
      console.log('payload',payload)
    if (status_code === 200) {
      setPlotPath(result["result"])
      let dataLights = await fetch(result["dataUrlLight"]).then((response) =>
        response.json());
    } else {
      console.error("Erreur dans la réponse :", status_code);
    }
    setIsLoading(false);
  }

  const handleSelectedLightsChange = React.useCallback((callback) => {
    let newData = callback(selected_lights);
    setCityLightsData(td => td.map((item) => {
      let newItem = newData.find((newItem) => newItem.id === item.id);
      return newItem ? {...newItem} : item;
    }));
  }, [selected_lights, setCityLightsData]);


  const paginate = React.useCallback((newDirection) => {
    setPage((prev) => {
      const nextPage = prev[0] + newDirection;
      if (nextPage < 0 || nextPage > 3) return prev;
      return [nextPage, newDirection];
    });
  }, []);
  const onChangePage = React.useCallback((newPage) => {
    setPage((prev) => {
      if (newPage < 0 || newPage > 3) return prev;
      const currentPage = prev[0];

      return [newPage, newPage > currentPage ? 1 : -1];
    });
  }, []);
  const onBack = React.useCallback(() => {
    paginate(-1);
  }, [paginate]);
  const onNext = React.useCallback(() => {
    paginate(1);
  }, [paginate]);

  return (
    <>
      <MultistepSidebar
        currentPage={page}
        onBack={onBack}
        onChangePage={onChangePage}
        onNext={onNext}
        allStatusGood={allStatusGood}
      >
        <div className="relative flex h-fit w-full flex-col pt-6 text-center lg:h-full lg:pt-0">
          <LazyMotion features={domAnimation}>
            <m.div
              key={page}
              animate="center"
              className="col-span-12 h-full w-full"
              custom={direction}
              exit="exit"
              initial="exit"
              transition={{
                y: {
                  ease: "backOut",
                  duration: 0.35,
                },
                opacity: { duration: 0.4 },
              }}
              variants={variants}
            >
              {page === 0 && 
                <LeafletMap 
                onRectangleCoordsChange={(coords) =>{setCalculationZone(coords)}} 
                show_roads={true} 
                roadsData = {roadsData}
                show_tools={true} 
                />
              }
              {page === 1 && 
              <EditableTable
                columns={initial_visible_columns}
                data={selected_lights}
                setData={handleSelectedLightsChange}
                pagination={true}
                showTopRibbon={true}
                filters={filters}
                rowsPerPage={10} />}
              {page === 2 && <LeafletMap needPlot={true} plotPath={plotPath} />}
            </m.div>
          </LazyMotion>
        </div>
        <RedirectionModal isOpen={showModal} onConfirm={()=>{
          setShowModal(false);
          navigate(`/solux-map/fichiers-ies-ldt`);
        }} 
        onDecline={
          ()=>{
          setShowModal(false);
            navigate(`/solux-map/accueil`)
          }
        }
        message ={<>Il semble qu’<b>aucun</b> fichier photométrique ne soit actuellement associé au <b>projet</b> en cours.</>} 
        buttonOkText={"Ajouter des fichiers"}
      /> 
      </MultistepSidebar>
    </>
  );
}
