import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, ScrollShadow,Select,SelectItem } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import EditableTable from "../../../../components/table/editable-table";
import ResultDisplay from "./result-display";
  
export default function LightsSelectionCard({ lightsData, setSelectedIES, props }) {
    const [manufacturer, setManufacturer] = React.useState();
    const [manufacturerTemperature, setManufacturerTemperature] = React.useState();
    const [manufacturerIntensity, setManufacturerIntensity] = React.useState();
    
    const [selectedManufacturer,setSelectedManufacturer] = React.useState();
    const [selectedTemperature,setSelectedTemperature] = React.useState();
    const [selectedIntensity,setSelectedIntensity] = React.useState();

    const getManufacturer = (data) =>{
      const luminairesParConstructeur = data.reduce((acc, item) => {
        const { manufacturer } = item;
        if (!acc[manufacturer]) {
          acc[manufacturer] = [];
        }
        acc[manufacturer].push(item);
        return acc;
      }, {});
    return luminairesParConstructeur
      }
    const getTemperatureFromManufacturer = ()=>{
      let data = manufacturer[selectedManufacturer]
      let res = data.reduce((acc, item) => {
        const { temperature, filename } = item;
        if (!acc[temperature]) {
          acc[temperature] = [];
        }
        acc[temperature].push(item);
        return acc;
      }, {});
      return res
    }
    const getIntensityFromManufacturer = () => {
      let data = manufacturerTemperature[selectedTemperature];

      let IntensityDict ={};
      const IntensityValues = [...new Set(data.map(item => parseFloat(item.intensity)))].sort((a, b) => a - b);

      IntensityValues.forEach(intensity => {
          IntensityDict[intensity] = data.filter(item => parseFloat(item.intensity) <= intensity);
      });
      
    return IntensityDict};


    useEffect(()=>{
    let tmp = getManufacturer(lightsData);
    setManufacturer(tmp);
    if(manufacturer){
      setSelectedManufacturer(Object.keys(manufacturer)[0])
    }
  },[lightsData])

  useEffect(()=>{
    if(manufacturer && selectedManufacturer && selectedManufacturer.length > 0){
        let tmp = getTemperatureFromManufacturer(selectedManufacturer);
        setManufacturerTemperature(tmp)
    }
    else{
      setManufacturerTemperature([])
    }
  },[selectedManufacturer])

  useEffect(()=>{
    if(manufacturer && selectedTemperature && selectedTemperature.length > 0){
        let tmp = getIntensityFromManufacturer(selectedManufacturer);
        setManufacturerIntensity(tmp)
    }
    else{
      setManufacturerIntensity([])
    }
  },[selectedManufacturer,selectedTemperature])

console.log('manufacturer',manufacturer)
console.log("manufacturerIntensity",manufacturerIntensity)

  return (
    <Card className="bg-content2" shadow="none" {...props}>
      <CardHeader className="flex flex-col gap-2 px-4 pb-4 pt-6">
        {/* <Icon icon="solar:lightbulb-line-duotone" width={40} /> */}
        <p className="text-medium text-content2-foreground">Choix luminaire(s)</p>
      </CardHeader>
      <CardBody className="flex flex-col gap-2">
        <ScrollShadow className="h-full">
          <div className="bg-white rounded-large shadow-small px-2 py-2 flex flex-row flex-nowrap justify-around items-center">
            <Select 
              className="max-w-xs" 
              label="Sélectionner une marque de luminaire"
              defaultSelectedKeys={selectedManufacturer}
              onChange={(e) => setSelectedManufacturer(e.target.value)}
            >
              {manufacturer && Object.keys(manufacturer)?.map((manufacture) => (
              <SelectItem key={manufacture} textValue={manufacture}>{manufacture}</SelectItem>
              ))}
            </Select>
            <Select 
              className="max-w-xs"
              label="Sélectionner une température"
              defaultSelectedKeys={selectedTemperature}
              onChange={(e) => {
                setSelectedTemperature(e.target.value)
                setSelectedIES(manufacturerTemperature[e.target.value].map((x) => x.filename))
              }
              } 
            >
                {manufacturerTemperature && Object.keys(manufacturerTemperature).map((temperature) => (
                <SelectItem key={temperature} textValue={temperature} >{temperature}</SelectItem>
                ))}
            </Select>
            <Select 
              className="max-w-xs"
              label="Sélectionner une intensité maximale"
              defaultSelectedKeys={selectedIntensity}
              onChange={(e) => {
                setSelectedIES(manufacturerIntensity[e.target.value].map((x) => x.filename))
              }
              }
            >
                {manufacturerIntensity && Object.keys(manufacturerIntensity).map((intensity) => (
                <SelectItem key={intensity} textValue={intensity} >{intensity} </SelectItem>
                ))}
            </Select>

          </div>
        </ScrollShadow>
      </CardBody>
    </Card>
  );

}
