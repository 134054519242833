import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Input } from "@nextui-org/react";
import React , { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CanvasContext } from "./contexts/canvas-context";
import { setPageRealDistanceCalibration, setAllPagesScale} from "../../store/components/PolygonEditor/ImageSlice";
import { useDrawShape} from"./contexts/draw-context";
import { setOpacity, setStrokeColor } from "../../store/components/PolygonEditor/CanvasSlice";
import { ShapeType } from "./hooks/draw";
import YesNoAlert from '../modals/AlertYesNo';
import DotInputNumber from "../inputs/dot-input-number";

const Calibration = ({
    activeFunction, // "line_calibration", "calibration"
    setActiveFunction,
    page,
    pagesNumbers
}) => {
    const calibrationPoints = useSelector((state) => state.image.calibration?.find(c=>c.page === page)?.points ?? []);
    const calibrationDistance = useSelector((state) => state.image.calibration?.find(c=>c.page === page)?.real_distance ?? 0);
    const allPagesHaveSameScale = useSelector((state) => state.image.calibration?.every((p, _, arr) => p.scale === arr[0].scale));
    const { canvasRef, setIsDeleting , setShapeType , getAllObjects , removeObjects , getObjectById , setMaxPoints } = useContext(CanvasContext) ;
    const dispatch = useDispatch();
    const { startDrawing,insertPolygon,zoomObjects } = useDrawShape();
    const calibrationData = useSelector((state) => state.image.calibration?.find(c=>c.page === page) ?? undefined );

    const scaleLayerId = useSelector((state) => {
      let scale = state.image.layers?.find(x=>x.name === "scale_foreground_vpf");
      if (scale) {
        return scale.layerId;
      }
      else {
        return -1;
      }
    });

    const changeCalibrationValue = (value) => {
      dispatch(setPageRealDistanceCalibration({
        page: page,
        realDistance: value
      }));
    };

    const drawCalibration = () => {
      setIsDeleting(false);
      if (calibrationData !== undefined) {
        removeObjects(getAllObjects().filter(x=>x.layer === scaleLayerId && x.calibration_line === true).map(x=>x.id));
      }
      setActiveFunction("line_calibration");
      dispatch(setStrokeColor("#000000"));
      dispatch(setOpacity(1));
      setMaxPoints(2);
      setShapeType(ShapeType.POLYLINE);
      startDrawing();
    };

    const setAllPages = () => {
      const isSettingScale = YesNoAlert({
        title: "Echelle commune",
        body: `L'échelle de la page ${page} va être appliquée à toutes les pages. Continuer?`,
        yesText: "OK",
        noText: "Annuler",
        onYes: () => {
          dispatch(setAllPagesScale({page, pagesNumbers}))
        },
        
      });
    };

    return <div className='flex flex-col gap-y-2'>
              <div>Calibration</div>
              <div className='flex flex-row justify-around items-center align-middle gap-x-4'>
                  <Button onClick={drawCalibration} color={activeFunction === "line_calibration" ? "danger" : "default"} className="shadow-lg"><Icon icon="lets-icons:line-out" className='scale-150' />Sélectionner une longueur</Button>
                  {(calibrationPoints && calibrationPoints.length === 2) && <Icon icon="entypo:check" className='scale-[2]' />}
              </div>
              <div className="glass p-3 rounded-2xl">
                <div className="text-default-400 text-sm mb-1">Longueur associée</div>
                <div className="flex flex-row items-center">
                  <DotInputNumber placeholder={calibrationDistance ? calibrationDistance : "1"} className="rounded-sm" min={0.01} step={0.1} onValueChange={changeCalibrationValue} />
                  <div className="text-default-400 ml-1">m</div>
                </div>
              </div>
              <Button isDisabled={allPagesHaveSameScale} onClick={setAllPages} className="shadow-lg">
                {allPagesHaveSameScale ? 
                    <div className="inline-flex items-center">
                      <Icon icon="entypo:check" className="mr-1"/><span>Toutes les pages ont la même échelle</span>
                    </div>
                    : <div>Appliquer l'échelle à toutes les pages</div>
                }
              </Button>
              {/* <Button isDisabled={!calibrationReady} onClick={drawCalibration} color={activeFunction === "calibration" ? "danger" : "default"}><Icon icon="ph:ruler" className='scale-150' />Calibrer</Button> */}
          </div>
};

export default Calibration;