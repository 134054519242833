import ReactDOM from 'react-dom/client';
import DianeModal from "./DianeModal";
import { Button, useDisclosure } from "@nextui-org/react";
import React, { useState } from 'react';

export default function Alert(title, body, props = {}) {
    // Simply call this function to display a modal alert
    const modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);

    const DianeModalAlert = ({ title, body, ...props }) => {
        const { isOpen, onClose } = useDisclosure({ defaultOpen: true });
        
        function closeAction() {
            onClose();
            if (props.hasOwnProperty("closeEvent") && props.closeEvent !== undefined) {
                props["closeEvent"]();
            }
        
            if (document.body.contains(modalRoot)) {
                document.body.removeChild(modalRoot);
            }
        }

        return (
            <DianeModal
                title={title}
                body={body}
                placement='top'
                size='sm'
                footer={
                    <Button 
                        color='default'
                        variant='flat'
                        onClick={closeAction}
                    >Fermer</Button>
                }
                isOpen={isOpen}
                onClose={closeAction}
                {...props}

            />
        );
    };

    ReactDOM.createRoot(modalRoot).render(<DianeModalAlert title={title} body={body} {...props} />);
}




