export const lightsCat =  {
    'C0':{'e_moy':40,'uniformity':0.4},
    'C1':{'e_moy':30,'uniformity':0.4},
    'C2':{'e_moy':20,'uniformity':0.4},
    'C3':{'e_moy':15,'uniformity':0.4},
    'C4':{'e_moy':10,'uniformity':0.4},
    'C5':{'e_moy':7.5,'uniformity':0.4},
    'P1':{'e_moy':15,'uniformity':0.4},
    'P2':{'e_moy':10,'uniformity':0.4},
    'P3':{'e_moy':7.5,'uniformity':0.4},
    'P4':{'e_moy':5,'uniformity':0.4},
    'P5':{'e_moy':3,'uniformity':0.4},
    'P6':{'e_moy':2,'uniformity':0.4},
    'M1':{'cd_m2':2,'uniformity':0.4},
    'M2':{'cd_m2':1.5,'uniformity':0.4},
    'M3':{'cd_m2':1,'uniformity':0.4},
    'M4':{'cd_m2':0.75,'uniformity':0.4},
    'M5':{'cd_m2':0.5,'uniformity':0.4},
    'M6':{'cd_m2':0.3,'uniformity':0.4},
}
export const roadCat= ["Axe principal", "Axe secondaire", "Axe tertiaire", "Axe piéton"];
