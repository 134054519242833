import { Card, CardBody, CardHeader, Button, Tooltip,ScrollShadow,Image,RadioGroup, Radio} from "@nextui-org/react";
import { Icon } from "@iconify/react";
import React, { useEffect, useMemo } from "react";
import EditableTable from "../../../../components/table/editable-table";
import { useDispatch, useSelector } from "react-redux";
import { RoadTypeEnum } from "./roads-types"
import { reverseEasing } from "framer-motion";

const defaultValue = [
  { type: RoadTypeEnum.Chaussee, default_name: 'Chaussée', category: 'Axe principal', width: 7, height: 0, light_height: 8, nb_way: 2, lighting_class: 'C0', color: '#5c523e' },
  { type: RoadTypeEnum.Trottoire, default_name: 'Trottoir', category: 'Axe principal', width: 2, height: 0.1, light_height: 8, nb_way: 0, lighting_class: 'C0', color: '#a49e87' },
  { type: RoadTypeEnum.PisteCyclable, default_name: 'Piste cyclable', category: 'Axe principal', width: 1, light_height: 8, height: 0.1, nb_way: 0, lighting_class: 'C0', color: '#c4bdac' },
  { type: RoadTypeEnum.Stationnement, default_name: 'Stationnement', category: 'Axe principal', width: 2.5, light_height: 8, height: 0, nb_way: 0, lighting_class: 'C0', color: '#e8e6d9' },
  { type: RoadTypeEnum.TerrePlein, default_name: 'Terre-plein', category: 'Axe principal', width: 4, height: 0.1, light_height: 8, nb_way: 0, lighting_class: 'C0', color: '#90a955' },
  { type: RoadTypeEnum.BAU, default_name: "Bande d'arrêt d'urgence", category: 'Axe principal', width: 3, height: 0, light_height: 8, nb_way: 0, lighting_class: 'C0', color: '#ffa62b' },
  { type: RoadTypeEnum.TerrePleinCentral, default_name: 'Terre-plein central', category: 'Axe principal', width: 1, light_height: 8, height: 0.1, nb_way: 0, lighting_class: 'C0', color: '#4f772d' },
]

export default function RoadTypeCard({ roadProfileData,roadData, setRoadProfileData,setRoadData, onRowSelected, onRoadCreated, props }) {
  const [filters, setFilters] = React.useState([]);

  var initial_visible_columns = useMemo(() => {
    return [
      {
        name: "Designation",
        uid: "designation",
        isEditable: true,
        type: 'text',
      },
      {
        name: "Largeur (m)",
        uid: "width",
        isEditable: true,
        type: 'number',
        min: 1,
        max:50,
      },
      {
        name: "Hauteur (m)",
        uid: "height",
        isEditable: true,
        type: 'number',
        min:0,
        max:50,

      },
      {
        name: "Nombre de voies",
        uid: "nb_way",
        isEditable: true,
        type: 'number',
        min:1,
        max:50,
      },
      {
        name: "",
        uid: "action",
        isEditable: false,
        type: 'text',
        renderCell: (item) => {
          return (
            <div className="flex flex-row gap-1">
              <Button isIconOnly variant="light" onClick={() => handleUp(item)}>
                <Icon icon="solar:square-alt-arrow-up-line-duotone" width={25} />
              </Button>
              <Button isIconOnly variant="light" onClick={() => { handleDown(item) }}>
                <Icon icon="solar:square-alt-arrow-down-line-duotone" width={25} />
              </Button>
              <Button isIconOnly variant="light" onClick={() => { handleDelete(item) }}>
                <Icon icon="solar:trash-bin-minimalistic-2-line-duotone" width={25} style={{ color: "#ff0000" }} />
              </Button>
            </div>
          )
        }
      }
    ];
  }, [roadProfileData]);

  function reOrderRectangles(items) {
    let reversed = [...items].reverse();
    let tmpRectangles = [];
    let highestRectangle = 0;

    if (reversed) {
      reversed.forEach((element, idx) => {
        if (idx === 0) { tmpRectangles.push(highestRectangle) }
        else {
          highestRectangle = highestRectangle + reversed[idx - 1].rectangle.height / 2 + element.rectangle.height / 2
          tmpRectangles.push(highestRectangle)
        }
      });
      let rectangleHeights = tmpRectangles.reverse();
      items.forEach((e, i) => e.rectangle.position[1] = rectangleHeights[i])
    }
    return items

  }

  const handleUp = (itemSelected) => {
    setRoadProfileData((items) => {
      let prevItems = [...items]
      const index = prevItems.findIndex(item => item.id === itemSelected.id);
      if (index === 0) return prevItems;
      const element = prevItems.splice(index, 1)[0];
      prevItems.splice(index - 1, 0, element);
      reOrderRectangles(prevItems);
      return prevItems
    });
  };
  const handleDown = (itemSelected) => {
    setRoadProfileData((items) => {
      let prevItems = [...items]
      const index = prevItems.findIndex(item => item.id === itemSelected.id);
      if (index === -1) return;
      const element = prevItems.splice(index, 1)[0];
      prevItems.splice(index + 1, 0, element);
      reOrderRectangles(prevItems);
      return prevItems
    });
  };
  const handleDelete = (itemSelected) => {
    setRoadProfileData((items) => {
      let prevItems = [...items]
      let newItems = prevItems.filter(item => item.id !== itemSelected.id);
      reOrderRectangles(newItems);
      return newItems
    });
  };

  function getDefaultValues(roadType) {
    return defaultValue.find(item => item.type === roadType);
  }

  const handleAdd = (roadType) => {
    setRoadProfileData((items) => {
      let prevItems = [...items];
      let highestRectangle = 0;

      if (prevItems) {
        let highestRectangleObj = prevItems.reduce((highest, current) => {
          return current.rectangle.position[1] > highest.rectangle.position[1] ? current : highest;
        }, prevItems[0]);
        let tmp = getDefaultValues(roadType);
        highestRectangle = highestRectangleObj.rectangle.position[1] + (highestRectangleObj.rectangle.height / 2) + (tmp.width / 4)
      }

      const maxId = prevItems.reduce((max, item) => item.id > max ? item.id : max, prevItems[0].id);
      let filteredItems = prevItems.filter(item => item.type === roadType);

      if (filteredItems) {
        const regex = /\d+/;
        const maxNumber = filteredItems.reduce((max, item) => {
          const currentMatch = item.designation ? item.designation.match(regex) : null;
          const currentNumber = currentMatch ? parseInt(currentMatch[0]) : -1;
          return currentNumber > max ? currentNumber : max;
        }, -1);
        let defaultValues = getDefaultValues(roadType);



        prevItems.push({
          id: maxId + 1,
          type: roadType,
          width: defaultValues.width,
          height: defaultValues.height,
          nb_way: defaultValues.nb_way,
          designation: `${defaultValues.default_name} ${maxNumber + 1}`,
          rectangle: {
            id: maxId + 1,
            width: 15,
            height: defaultValues.width / 2,
            position: [0, highestRectangle, 0],
            color: defaultValues.color,
          }
        })
        return prevItems;
      }
      else {
        let defaultValues = getDefaultValues(roadType);

        prevItems.push({
          id: maxId + 1,
          type: roadType,
          width: defaultValues.width,
          height: defaultValues.height,
          nb_way: defaultValues.nb_way,
          designation: `${defaultValues.default_name} ${1}`,
          category:defaultValues.category,
          light_height:defaultValues.light_height,
          lighting_class:defaultValues.lighting_class,
          rectangle: {
            id: maxId + 1,
            width: 15,
            height: defaultValues.width / 2,
            position: [0, highestRectangle, 0],
            color: defaultValues.color,
          }
        })
      }
    });
  }
  console.log('roadProfileData',roadProfileData)
  return (
    <Card className="bg-content2 w-[50%] h-auto overflow-y-hidden" shadow="none" {...props}>
      <CardHeader className="flex flex-col gap-2 px-4 pb-4 pt-6">
        <Icon icon="solar:video-frame-cut-line-duotone" width={40} />
        <p className="text-medium text-content2-foreground">Profil de la rue</p>
      </CardHeader>
      <CardBody className="flex flex-col gap-2 w-full h-full">

        <div className="flex flex-row flex-wrap gap-2 justify-center">

          <Tooltip content="Ajouter une chaussée">
            <Button isIconOnly onClick={() => { handleAdd(RoadTypeEnum.Chaussee) }}>
              <Icon icon="solar:suspension-bold-duotone" width={20} />
            </Button>
          </Tooltip>

          <Tooltip content="Ajouter un trottoir">
            <Button isIconOnly onClick={() => { handleAdd(RoadTypeEnum.Trottoire) }}>
              <Icon icon="solar:user-hands-line-duotone" width={20} />
            </Button>
          </Tooltip>

          <Tooltip content="Ajouter une piste cyclable">
            <Button isIconOnly onClick={() => { handleAdd(RoadTypeEnum.PisteCyclable) }}>
              <Icon icon="solar:bicycling-line-duotone" width={20} />
            </Button>
          </Tooltip>

          <Tooltip content="Ajouter un accotement de stationnement">
            <Button isIconOnly onClick={() => { handleAdd(RoadTypeEnum.Stationnement) }}>
              <Icon icon="mynaui:parking" width={20} />
            </Button>
          </Tooltip>

          <Tooltip content="Ajouter un terre-plein">
            <Button isIconOnly onClick={() => { handleAdd(RoadTypeEnum.TerrePlein) }}>
              <Icon icon="uil:trees" width={20} />
            </Button>
          </Tooltip>

          <Tooltip content="Ajouter une bande d'arrêt d'urgence">
            <Button isIconOnly onClick={() => { handleAdd(RoadTypeEnum.TerrePleinCentral) }}>
              <Icon icon="mdi:car-parking-brake" width={20} />
            </Button>
          </Tooltip>

          <Tooltip content="Ajouter un terre-plein central">
            <Button isIconOnly onClick={() => { handleAdd(RoadTypeEnum.BAU) }}>
              <Icon icon="lucide:rectangle-vertical" width={20} />
            </Button>
          </Tooltip>
        </div>
        <ScrollShadow className="max-h-[60%]">
        <EditableTable
          defaultSelectedKeys={["1"]}
          columns={initial_visible_columns}
          data={roadProfileData}
          setData={setRoadProfileData}
          pagination={false}
          showTopRibbon={false}
          filters={filters}
          rowsPerPage={5}
          onSelectionChange={(key) => {
            onRowSelected(roadProfileData.filter(item => item.id === parseInt([...key][0]))[0]);
          }
          }
        />
        </ScrollShadow> 
        <div className="flex flex-col items-center">
          <Icon icon="solar:video-frame-cut-line-duotone"  width={40} />
          <p className="text-medium text-content2-foreground text-center mt-4">Disposition des luminaires</p>
          <div className=" flex flex-row justify-center gap-4 mt-2 mb-4">
          <RadioGroup 
            color="primary"
            value={roadData.light_dispo}
            onValueChange={(value) =>{
                setRoadData((items) => {
                  let prevItems = {
                    ...items,
                    ["light_dispo"]: value
                };
                return prevItems
              });      
            }
          }
            orientation="horizontal"
          >
            <Radio value="up">
              <Image
                style={{height:'45px'}}
                alt={''}
                className="aspect-square w-full"
                src={'/images/pym/road-up.png'}
              />
            </Radio>
            <Radio value="down">
              <Image
                style={{height:'45px'}}
                isBlurred
                alt={''}
                className="aspect-square w-full"
                src={'/images/pym/road-down.png'}
              />
            </Radio>
            <Radio  value="both">
              <Image
                style={{height:'45px'}}
                isBlurred
                alt={''}
                className="aspect-square w-full"
                src={'/images/pym/road-up-down.png'}
              />

            </Radio>
            <Radio value="quinc">
              <Image
                style={{height:'45px'}}
                isBlurred
                alt={''}
                className="aspect-square w-full"
                src={'/images/pym/road-quinconce.png'}
                />
            </Radio>
          </RadioGroup>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
