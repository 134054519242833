import React, { useRef, useState, useEffect } from 'react'
import { RoadTypeEnum } from "./roads-types"

import RoadProfile from './road-profile';

export default function Definition() {
  const roadProfileData = [
    {
      id: 1, type: RoadTypeEnum.Chaussee, designation: 'Chaussée 1', width: 7, nb_way: 2, height: 0,rectangle: {
        "width": 15,
        "height": 3.5,
        "position": [
          0,
          2.25,
          0
        ],
        "color": "#5c523e"
      }
    },
    {
      id: 2, type: RoadTypeEnum.Trottoir, designation: 'Trottoir 1', width: 2, nb_way: 0, height: 0.10,rectangle: {
        "width": 15,
        "height": 1,
        "position": [
          0,
          0,
          0
        ],
        "color": "#a49e87"
      }
    },
  ]
  const [roadData,setRoadData] = React.useState(
    {
      'name': 'Voirie type 1',
      'lightsHeight':8,
      'lightingClass':"C0",
      'category':"Axe principal",
      'lights_dist':30,
      'lights_dist_road':0,
      'road_profile_data':roadProfileData,
      'light_dispo':"up",
}
)
const [roadGeomData,setRoadGeomData] = React.useState(roadProfileData)
  return (
    <RoadProfile  _roadData ={roadData} _setRoadData={setRoadData} _roadGeomData={roadGeomData} _setRoadGeomData={setRoadGeomData}/>
  )
}
