import { lazy } from "react";
import ProtectedRoute from "../components/login/protected-route";
const DataQuality = lazy(() => import("../pages/data-apps/DataQuality"));

const DataAppsRoutes = {
  path: "/",
  element: "",
  children: [
    {
      path: "/data-apps/eve-data-quality",
      element: (
        <ProtectedRoute roles={["Plateforme.Service21", "Plateforme.FullAccess"]}>
          <DataQuality />
        </ProtectedRoute>
      ),
    }
  ],
};
export default DataAppsRoutes;
