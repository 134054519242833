import React from "react";
import ProtectedRoute from "../components/login/protected-route";

const GitHubActivityBusiness = React.lazy(() => import("../pages/business_github_activity/index"));
const Contracts = React.lazy(() => import("../pages/contracts_2/page/index"));
const Catalogue = React.lazy(() => import("../pages/contracts_2/page/Catalogue.js"));
const MyContracts = React.lazy(() => import("../pages/contracts_2/page/contracts.js"));
const FormationsCatalogue = React.lazy(() => import("../pages/contracts_2/page/formationCatalogue.js"));
const SignatureContrat = React.lazy(()=> import("../pages/contracts_2/page/signature.js"));
const Offre = React.lazy(()=> import("../pages/contracts_2/page/offre.js"));
const Formations = React.lazy(()=> import("../pages/contracts_2/page/formations.js"));
const AdminPage = React.lazy(()=> import("../pages/contracts_2/page/contractsAdmin.js"))
// const AdminContracts = React.lazy(() => import("../pages/services/Contractualisation/admin-contracts"));
// const AdminFormations = React.lazy(() => import("../pages/services/Contractualisation/admin-formations"));
// const AskContract = React.lazy(() => import("../pages/services/Contractualisation/ask-contract"));
// const AskFormation = React.lazy(() => import("../pages/services/Contractualisation/ask-formation"));
// // const Catalog = React.lazy(() => import("../pages/services/Contractualisation/catalog"));
// const MyContracts = React.lazy(() => import("../pages/services/Contractualisation/contracts"));
// const MyFormations = React.lazy(() => import("../pages/services/Contractualisation/formations"));

const router = {
  path: "/",
  element: "",
  children: [
    {
      path: "/contrats",
      element:  <ProtectedRoute roles={["Plateforme.FullAccess","Plateforme.Service22"]}>
                  <Contracts />
                </ProtectedRoute>
    },
    {
      path: "/contrats/mes_contrats",
      element:  <ProtectedRoute roles={["Plateforme.FullAccess","Plateforme.Service22"]}>
                  <MyContracts/>
                </ProtectedRoute>
    },
    {
      path: "/contrats/formations",
      element:  <ProtectedRoute roles={["Plateforme.FullAccess","Plateforme.Service22"]}>
                  <FormationsCatalogue/>
                </ProtectedRoute>
    },
    {
      path: "/contrats/catalogue",
      element:  <ProtectedRoute roles={["Plateforme.FullAccess","Plateforme.Service22"]}>
                  <Catalogue/>
                </ProtectedRoute>
    },
    {
      path: "/contrats/signature_contrat/:id",
      element:  <ProtectedRoute roles={["Plateforme.FullAccess","Plateforme.Service22"]}>
                  <SignatureContrat/>
                </ProtectedRoute>
    },
    {
      path: "/contrats/offre/:id",
      element:  <ProtectedRoute roles={["Plateforme.FullAccess","Plateforme.Service22"]}>
                  <Offre/>
                </ProtectedRoute>
    },
    {
      path: "/contrats/mes_formations",
      element:  <ProtectedRoute roles={["Plateforme.FullAccess", "Plateforme.Service22"]}>
                  <Formations/>
                </ProtectedRoute>
    },
    {
      path: "/contrats/contrats_admin",
      element:  <ProtectedRoute roles={["Plateforme.FullAccess"]}>
                  <AdminPage/>
                </ProtectedRoute>
    },
    {
      path: "/business_activity",
      element:  <ProtectedRoute roles={["Plateforme.FullAccess", "Plateforme.Service23"]}>
                  <GitHubActivityBusiness />
                </ProtectedRoute>
    }
  ],
};

export default router;

// {/* <ProtectedRoute roles={["baseauth", "Plateforme.Service17", "Plateforme.FullAccess"]}> */}
// const router = {
//   path: "/",
//   element: "",
//   children: [
//     {
//       path: "contrats_stephane",
//       element: (
//         <ProtectedRoute roles={["Plateforme.FullAccess"]}>
//           <Catalog />
//         </ProtectedRoute>
//       )
//     },
//     {
//       path: "/contrats",
//       element: (
//         <ProtectedRoute roles={["Plateforme.FullAccess"]}>
//             <Contracts />
//         </ProtectedRoute>
//       ),
//       children: [
//         {
//           path: "administrateur_contrats",
//           element: (
//             <ProtectedRoute roles={["Plateforme.FullAccess"]}>
//               <AdminContracts />
//             </ProtectedRoute>
//           )
//         },
//         {
//           path: "administrateur_formations",
//           element: (
//             <ProtectedRoute roles={["Plateforme.FullAccess"]}>
//               <AdminFormations />
//             </ProtectedRoute>
//           )
//         },
//         {
//           path: "mes_contrats",
//           element: <MyContracts />
//         },
//         {
//           path: "mes_formations",
//           element: <MyFormations />
//         },
//         {
//           path: "catalogue",
//           element: <Catalog />
//         },
//         {
//           path: "demande_solution",
//           element: <AskContract
//             specific={false}
//           />
//         },
//         {
//           path: ":id/demande_solution",
//           element: <AskContract
//             specific={true}
//           />
//         },
//         {
//           path: "demande_formation",
//           element: <AskFormation
//             specific={false}
//           />
//         },
//         {
//           path: ":id/demande_formation",
//           element: <AskFormation
//             specific={true}
//           />
//         }
//       ]
//     }
//   ],
// };

// export default router;
