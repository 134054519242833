import React, { createContext, useContext, useState } from 'react';

const SoluxMapContext = createContext();

export const useSoluxMap = () => {
    return useContext(SoluxMapContext);
};

export const SoluxMapProvider = ({ children }) => {
    const [cityLightsData, setCityLightsData] = useState([]);
    const [roadsData, setRoadsData] = useState([]);

    return (
        <SoluxMapContext.Provider value={{
            cityLightsData,
            setCityLightsData,
            roadsData,
            setRoadsData
        }}>
            {children}
        </SoluxMapContext.Provider>
    );
};
